<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card shadow-sm">
          <div class="container-fluid">
            <div class="row">
              <div class="col text-left">
                <div class="card-body">
                  <h5 class="card-title">Submit Ticket</h5>
                  <p class="card-text"></p>
                </div>
              </div>
            </div>
          </div>
          <form id="frmAdd" action="#" method="post">
            <ul class="list-group list-group-flush">
              <li id="tab-content" class="list-group-item">
                <p>
                  Please describe the issue you are having as thoroughly as
                  possible.
                </p>
                <autocomplete
                  :search="searchUsers"
                  :get-result-value="getUserValue"
                  :default-value="formatUser(userProfile.uid)"
                  overwrite-class="sender"
                  class="mb-3"
                  placeholder="Sender"
                  @change="updateSender"
                ></autocomplete>
                <input
                  id="inpNewTicketSubject"
                  type="text"
                  class="form-control mb-3"
                  placeholder="Subject"
                  v-model="ticket.subject"
                />
                <b-form-textarea
                  v-model="ticket.message"
                  placeholder="Thorough description..."
                  rows="8"
                  max-rows="8"
                  no-resize
                  class="mb-3"
                ></b-form-textarea>
                <b-form-checkbox
                  v-model="assignToMe"
                  :value="true"
                  :unchecked-value="false"
                >
                  Assign to me
                </b-form-checkbox>
              </li>
              <li class="list-group-item">
                <b-button
                  :to="
                    id === null
                      ? { name: 'helpdesk-inbox' }
                      : { name: 'helpdesk-shared', params: { slug: slug } }
                  "
                  variant="outline-primary"
                  class="mr-2"
                  ><i class="far fa-arrow-alt-circle-left"></i> Back</b-button
                >
                <b-button variant="outline-success" @click="submit">
                  <b-spinner small v-if="processing"></b-spinner>
                  <span v-if="!processing"
                    ><i class="far fa-save"></i> Submit</span
                  ></b-button
                >
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Ticket from "@/firebase/tickets";
import Autocomplete from "@/components/Autocomplete";
import Users from "@/firebase/users";
import moment from "moment";

export default {
  name: "SubmitTicket",
  mixins: [FirebaseMixin, UtilsMixin],
  components: { Autocomplete },
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    slug: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      ref: "tickets",
      bind: [],
      ticket: {
        sender: {},
        subject: "",
        message: ""
      },
      users: [],
      assignToMe: false,
      processing: false
    };
  },
  watch: {
    users: {
      immediate: true,
      handler(users) {
        users.forEach((user, index) => {
          if (
            !user.auth.customClaims ||
            typeof user.auth.customClaims !== "object"
          ) {
            this.users[index].auth.customClaims = {};
          }
          if (!user.db.profile || typeof user.db.profile !== "object") {
            this.users[index].db.profile = {};
          }
          if (!Array.isArray(user.db.roles)) {
            this.users[index].db.roles = [];
          }
        });
      }
    },
    assignToMe: {
      immediate: true,
      handler(assignToMe) {
        if (assignToMe) {
          this.ticket.assigned = {
            uid: this.userProfile.uid,
            name: this.userProfile.name
          };
        } else {
          this.ticket.assigned = null;
        }
      }
    }
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["userAuthorName"])
  },
  methods: {
    formatDate(date) {
      return moment(date).fromNow();
    },
    submit() {
      this.processing = true;
      const t = new Ticket();
      t.submit(this.ticket)
        .then(() => {
          window.toastr.success("Ticket submitted successfully.");
          if (this.id !== null) {
            this.navigate("helpdesk-shared", { id: this.id });
          } else {
            this.navigate("helpdesk-inbox");
          }
        })
        .catch(error => {
          console.log(error);
          window.toastr.error(
            "An error occurred while submitting the ticket. Please try again later."
          );
          this.processing = false;
        });
    },
    searchUsers(input) {
      if (input.length < 1) {
        return [];
      }
      return this.users.filter(user => {
        return (
          user.auth.displayName.toLowerCase().startsWith(input.toLowerCase()) ||
          user.auth.email.toLowerCase().startsWith(input.toLowerCase())
        );
      });
    },
    formatUser(user) {
      if (user && typeof user === "object" && "auth" in user) {
        return user.auth.displayName + " (" + user.auth.email + ")";
      } else {
        const u = this.users[this.getIndex(this.users, user, "uid")];
        if (u && "auth" in u) {
          return u.auth.displayName + " (" + u.auth.email + ")";
        } else {
          return u;
        }
      }
    },
    getUserValue(result) {
      return this.formatUser(result);
    },
    updateSender(value) {
      const user = { ...value };
      if (user && "auth" in user) {
        this.ticket.sender = {
          uid: user.auth.uid,
          name: user.auth.displayName,
          avatar: user.db.profile.avatar
        };
        if ("customClaims" in user.auth) {
          if (user.auth.customClaims.admin) {
            this.ticket.sender.type = "Administrator";
          } else if (user.auth.customClaims.subscribed) {
            this.ticket.sender.type = "Premium Member";
          } else {
            this.ticket.sender.type = "Free Member";
          }
        } else {
          this.ticket.sender.type = "Free Member";
        }
      }
    },
    getUsers() {
      this.loading = true;
      const u = new Users();
      u.getUsers()
        .then(resp => {
          this.users = [...resp.data.users];
          this.loading = false;
        })
        .catch(error => {
          console.error("Unable to fetch users", error);
          this.loading = false;
        });
    }
  },
  created() {
    this.getUsers();
  }
};
</script>

<style>
.sender-input {
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sender-input:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.sender-result-list {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  list-style-type: none;
  cursor: pointer;
}

.sender-result-list:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>
